<template>
  <!-- 商品列表 -->
  <div class="goodBox">
    <div :class="topclose ? 'ophead' : 'ophead vs'">
      <OpenAppHead :options="options" @getshowimg="getshowimg" />

    </div>
    <div>

      <div
        v-for="(item, index) in goodList"
        v-show="item.product_type != 8"
        :key="index"
        class="shop_list"
        @click="goBuy(item)"
      >
        <div class="img_box">
          <img :src="item.cover" alt="">
          <div class="num">{{ index + 1 }}</div>
        </div>
        <div class="shop_cont">
          <p class="title">
            <span class="bg bg_1">{{ item.product_fist_name }}</span><span class="bg bg_2" :style="{ background: item.color_type }">{{ item.product_type_name }}</span>{{ item.name }}
          </p>
          <p v-if="item.product_type != 8" class="stock">库存：{{ item.inventory }}</p>
          <div class="shop_price">
            <p class="price">
              <span style="font-size: 0.3rem;">¥</span>{{ item.price }}
              <span class="old_price">原价：{{ item.original_price }}</span>
            </p>
            <div class="btn">立即购买</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGoodsInfo } from '@/services/api'
import OpenAppHead from '@/component/GoodsDetail/openApp'
import { getSession, getUrlParams } from '@/utils/common/utils'

export default {
  name: 'LiveList',
  components: {
    OpenAppHead
  },
  data() {
    return {
      options: {
        showtig: true,
        info: {}
      },
      topclose: true,
      share_mid: '',
      live_id: '',
      goodList: [],
      mid: '',
      share_type: '',
      live_people_id: ''
    }
  },
  created() {
    const vm = this
    const { share_mid, id, share_type, live_people_id = '' } = vm.$route.query
    vm.share_mid = share_mid
    vm.live_id = id
    vm.share_type = share_type
    vm.live_people_id = live_people_id
    vm.getList(id)
    vm.getShare()
    // eslint-disable-next-line eqeqeq
    document.title = vm.share_type == 0 ? '购物袋' : '爆款推荐'
  },
  mounted() {
  },
  methods: {
    getshowimg(item) {
      this.showimg = item
    },
    isclose(t) {
      this.topclose = t
    },
    // 跳转购买
    goBuy(item) {
      const obj = {
        goodId: item.product_pk, // 商品goodid
        skuId: item.sku_id, // 商品的SKU
        product_type: ''
      }
      // eslint-disable-next-line eqeqeq
      if (item.product_type == 2) {
        const body = {
          goodsId: item.middle_product_id,
          skuId: item.middle_product_sku_id,
          live_people_id: this.live_people_id,
          live_id: this.live_id
        }
        getGoodsInfo(body).then((res) => {
          if (Number(res.code) === 200) {
            // 必传参数
            obj.product_type = 1 // cps
            obj.goodId = res.data.goodsId // cps
            obj.skuId = res.data.skuId // cps
            this.$store.dispatch('godetail', obj)
          }
        })
      } else {
        switch (
          item.product_type // 大人自营商品转换
        ) {
          case 9:
            obj.product_type = 3 // 京东
            break
          case 10:
            obj.product_type = 4 // pdd
            break
          case 11:
            obj.product_type = 6 // 唯品会
            break
        }
        this.$store.dispatch('godetail', obj)
      }
    },
    getShare() {
      const that = this
      const { live_id = 0 } = this.$route.query
      // eslint-disable-next-line no-undef
      $.ajax({
        url: 'https://gateway.daren.tech/open/v1/app/LiveVideo/getSecondShareInfo',
        type: 'GET',
        data: {
          live_id: live_id
        },
        success: function(res) {
          that.share(res.data)
        }
      })
    },
    getList(id) {
      const that = this
      let url = 'https://gateway.daren.tech/open/v1/app/LiveGoods/getLiveShoppingListV3'
      // eslint-disable-next-line eqeqeq
      if (that.share_type == 1) {
        // 爆款
        url = 'https://open.daren.tech/app/LiveGoods/getHotGoodsList'
      }
      const { live_id = 0, share_mid = 0 } = getUrlParams() || {}
      // eslint-disable-next-line no-undef
      $.ajax({
        url: url,
        type: 'GET',

        data: {
          live_mid: share_mid,
          channel: '3',
          id: live_id,
          mid: getSession('uid') || 0
        },
        success: function(res) {
          that.states = Number(res.code)
          if (Number(res.code) === 200) {
            that.goodList = res.data
            // that.share(res.data)
          } else if (Number(res.code) === 403) {
            // 下播
            that.player = null
            // that.bgurl = res.data
            that.xiabo = res.LiveInfo
            that.liveNum = res.LiveInfo.liveNum
            that.share(res)
          } else if (Number(res.code) === 405) {
            // 课程
            that.player = null
            that.bgurl = res.data
            that.tips = res.msg
            that.share(res)
          } else if (Number(res.code) === 401) {
            // 预约
            that.notDetail = res.data
            that.is_pay = res.is_pay // 1已支付 非1未支付
            that.course = res.data.product[0]
            if (that.notDetail.amount > 0) {
              that.isLogin = '1'
            } else {
              that.isLogin = '0'
            }
            that.share(res)
            setInterval(function() {
              // eslint-disable-next-line no-undef
              GetRTime(res.data.start_time)
            }, 1000)
          } else {
            alert(res.msg)
          }
        }
      })
    },
    share(cont) {
      var ajaxUrl =
        'https://gateway.yuetao.vip/center/v1/app/WechatShareH5?share_url=' + encodeURIComponent(window.location.href)
      // eslint-disable-next-line no-undef
      $.ajax({
        async: true,
        url: ajaxUrl,
        type: 'GET',
        dataType: 'jsonp',
        jsonpCallback: 'sharecb',
        success: function(data) {
          var link = window.location.href
          var title = cont.main_title
          var imgurl = cont.live_cover
          var desc = cont.live_title

          // eslint-disable-next-line no-undef
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.app_id, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名，见附录1
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone'
            ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            openTagList: ['wx-open-launch-app']
          })
          // eslint-disable-next-line no-undef
          wx.ready(function() {
            // 分享到朋友圈
            // eslint-disable-next-line no-undef
            wx.onMenuShareTimeline({
              title: title, // 分享标题
              link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: imgurl, // 分享图标
              success: function() {
                // 用户确认分享后执行的回调函数
                // alert('分享朋友圈成功');
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              }
            })

            // 分享给朋友
            // eslint-disable-next-line no-undef
            wx.onMenuShareAppMessage({
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: imgurl, // 分享图标
              type: 'link', // 分享类型,music、video或link，不填默认为link
              dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
              success: function() {
                // 用户确认分享后执行的回调函数
                // alert('分享给朋友');
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              }
            })

            // 分享qq
            // eslint-disable-next-line no-undef
            wx.onMenuShareQQ({
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: link, // 分享链接
              imgUrl: imgurl, // 分享图标
              success: function() {
                // 用户确认分享后执行的回调函数'
                // alert('qq');
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              }
            })

            // 分享qq空间
            // eslint-disable-next-line no-undef
            wx.onMenuShareQZone({
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: link, // 分享链接
              imgUrl: imgurl, // 分享图标
              success: function() {
                // 用户确认分享后执行的回调函数
                // alert('qqzone');
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              }
            })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
html {
  font-size: 13.33vw;
  height: 100%;
}

.goodBox {
  border-top: 0.01rem solid #e8e8e8;
}

.shop_list {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  color: #333333;
  padding: 0.3rem 0;
  margin: 0 0.3rem;
  border-bottom: 0.01rem solid #e8e8e8;
}

.img_box {
  position: relative;
  border-radius: 0.04rem;
  overflow: hidden;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
}

.img_box .num {
  position: absolute;
  left: 0;
  top: 0;
  width: 0.42rem;
  height: 0.3rem;
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 0.04rem;
  color: #fff;
  text-align: center;
  line-height: 0.3rem;
  font-size: 0.24rem;
}

.shop_list img {
  width: 1.8rem;
  height: 1.8rem;
}

.shop_list .shop_cont {
  flex: 1;
}

.shop_list .shop_cont .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
}

.stock {
  color: #999;
  font-size: 0.24rem;
  line-height: 0.5rem;
  margin-top: 0.1rem;
  text-align: left;
}

.shop_list .shop_cont .shop_price {
  color: #eb374b;
  font-size: 0.42rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop_list .shop_cont .shop_price .btn {
  width: 1.66rem;
  height: 0.62rem;
  line-height: 0.62rem;
  text-align: center;
  background: linear-gradient(264deg, rgba(241, 56, 75, 1), rgba(244, 71, 88, 1));
  border-radius: 0.31rem;
  color: #fff;
  font-size: 0.26rem;
}

.bg {
  padding: 0 0.08rem;
  height: 0.32rem;
  text-align: center;
  line-height: 0.32rem;
  color: #fff;
  font-size: 0.22rem;
  display: inline-block;
  vertical-align: middle;
}

.bg_1 {
  background: #ff0d45;
  border-radius: 0.04rem 0 0 0.04rem;
}

.bg_2 {
  border-radius: 0 0.04rem 0.04rem 0;
  margin-right: 0.1rem;
}

.old_price {
  color: #999;
  font-size: 0.22rem;
  text-decoration: line-through;
  vertical-align: middle;
}
</style>
